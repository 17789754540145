'use strict';

import '../css/app.scss';

async function loadJQuery() {
    const {default: jQuery} = await import(
        /* webpackChunkName: "jquery" */
        'jquery/dist/jquery'
    );
    return jQuery;
}
async function loadBootstrap() {
    const {default: Bootstrap} = await import(
        /* webpackChunkName: "bootstrap" */
        'bootstrap/dist/js/bootstrap'
    );
    return Bootstrap;
}

async function loadFontAwesome() {
    const {default: FontAwesome} = await import(
        /* webpackChunkName: "font-awesome" */
        './font-awesome.js'
    );
    return FontAwesome;
}

loadJQuery().then((jQuery) => {
    /* fix for https://github.com/symfony/webpack-encore/pull/54 */
    global.$ = global.jQuery = jQuery;

    loadBootstrap().then(() => {
    });

    loadFontAwesome().then(() => {
    });

    // require('ckeditor/ckeditor.js');

    global.toastr = require('toastr/build/toastr.min.js');
    toastr.options = {
        'closeButton': true,
        'debug': false,
        'newestOnTop': true,
        'progressBar': true,
        'positionClass': 'toast-top-right',
        'preventDuplicates': false,
        'onclick': null,
        'showDuration': 300,
        'hideDuration': 1000,
        'timeOut': 5000,
        'extendedTimeOut': 2000,
        'showEasing': 'swing',
        'hideEasing': 'swing',
        'showMethod': 'slideDown',
        'hideMethod': 'slideUp'
    };

    require('./javascript.js');
    require('./fancybox.js');
    require('./leaflet.js');
    require('./fullscreen.js');
});
